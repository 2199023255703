import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { getEnvironment } from './components/helpers'

if (getEnvironment() !== 'local') {
  Sentry.init({
    dsn: "https://5d672574919144b1945e2339492b1faf@o248220.ingest.sentry.io/5678960",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: '838c36277ed3ba7d1fa8559a143024b2a769d652',
    environment: getEnvironment(),
    denyUrls: [
      /(www\.)?googletagmanager\.com/i
    ]
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
