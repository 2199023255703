import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import useAxios from "axios-hooks";

import {Card, Spinner} from "rt-design-system-backup";

import Router from "./Router";
import Layout from "./Layout";
import Routes from "./Routes";

import {Login} from "./Login";
import Logout from "./Logout";
import {has_no_permissions, hasGroup, hasPerm, isTokenExpired, url} from "../helpers";
import AppContext from "./AppContext";
import GlobalToaster from "./GlobalToaster";
import SocketContext, {socketManager} from "./SocketContext";
import MarineContext from "./MarineContext";
import axios from "axios";
import {MappingValidationContextProvider} from "./MappingValidation/MappingValidationContext";
import {STPMappingSystemColumnContextProvider} from "./STPMappingSystemColumns/STPMappingSystemColumnsContext";


const get_socket_connection_path = function () {
    const access_token = localStorage.getItem("accessToken");
    let path = url("ws/data/").replace("http", "ws");
    path = path + `?token=${access_token}`;
    return path
};

let connection = new WebSocket(get_socket_connection_path());

connection.onopen = (e) => {
    console.log("connection established");
};

const socketManagerInstance = socketManager(connection);

const refreshSocketConnection = () => {
    if (connection.readyState <= 1) {
        return;
    }
    connection.close();

    const newConnection = new WebSocket(get_socket_connection_path());

    newConnection.onerror = refreshSocketConnection;
    newConnection.onclose = refreshSocketConnection;

    connection = newConnection;

    socketManagerInstance.dispatchEvent(
        new CustomEvent("~wsupdate", {
            detail: {
                socket: newConnection,
            },
        })
    );
};

connection.onerror = refreshSocketConnection;

connection.onclose = refreshSocketConnection;

const App = () => {

    axios.interceptors.request.use(
        (config) => {
            const accessToken = localStorage.getItem("accessToken");
            if (accessToken) {
                config.headers["Authorization"] = "Bearer " + accessToken;
                console.log({accessToken})
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );

    let refresh_expired = false;
    const [isDarkModeOn, setIsDarkModeOn] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [mainPadding, setMainPadding] = useState('');

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        function (error) {
            if (!error.response) {
                console.log(error);
                return Promise.reject(error);
            }

            const originalRequest = error.config;
            let accessToken = localStorage.getItem("accessToken");
            let refreshToken = localStorage.getItem("refreshToken");

            if (refreshToken && error.response.status === 401 && isTokenExpired(accessToken) && (!isTokenExpired(refreshToken))) {
                return axios
                    .post(url('/token/refresh/'), {refresh: refreshToken})
                    .then((res) => {
                        if (res.status === 200) {
                            localStorage.setItem("accessToken", res.data.access);
                            console.log("Access token refreshed!");
                            return axios(originalRequest);
                        }
                    });
            }
            return Promise.reject(error);
        }
    );

    const [{data, error, loading}, refetch] = useAxios({
        url: url("profile/")
    });

    if (data && data.email) {
        localStorage.setItem("user_email", data.email);
    }

    const setAuthToken = (access_token, refresh_token) => {
        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("refreshToken", refresh_token);
    };

    let refreshToken = localStorage.getItem("refreshToken");
    refresh_expired = isTokenExpired(refreshToken);
    if (refresh_expired) {
        localStorage.clear()
    }

    const is_user_inactive = (error) => {
        if (!error || !error.response || !error.response.data || !error.response.data.code) {
            return false
        }
        let user_inactive = error.response.data.code === 'user_inactive'
        if (user_inactive) {
            localStorage.clear()
        }
    }

    const access_token = localStorage.getItem("accessToken");
    const needs_login = error && (!error.response || error.response.status === 401) && (!access_token || refresh_expired || is_user_inactive(error));

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === "v") {
                alert("20240801.2/838c36277ed3ba7d1fa8559a143024b2a769d652");
            }
        });
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let query_param_access_token = params.get('accessToken');
        let query_param_refresh_token = params.get('refreshToken');
        if (query_param_access_token && query_param_refresh_token) {
            localStorage.clear();
            setAuthToken(query_param_access_token, query_param_refresh_token);
        }
    });

    const unreachable =
        error && (!error.response || error.response.status !== 403) && !needs_login;

    const loggedIn = data && data.email && !error && !needs_login;
    const userHasNoPermissions = has_no_permissions(data);

    return (!loggedIn && loading) || unreachable ? (
        <div
            style={{
                padding: "32px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
            }}
        >
            {loading ? (
                <Spinner size={48}/>
            ) : (
                <Card elevation={2}>
                    Please connect to the Rio Tinto network to access
                    https://commercialmarketsdesk.net/
                </Card>
            )}
        </div>
    ) : (
        <SocketContext.Provider value={socketManagerInstance}>
            <MappingValidationContextProvider>
                <STPMappingSystemColumnContextProvider>
                    <Router>
                        <AppContext.Provider
                            value={{
                                refreshUser: refetch,
                                user: data,
                                loggedIn,
                            }}
                        >
                            <Switch>
                                <Route path="/login/:showSSO?">
                                    {loggedIn && data && userHasNoPermissions ? (
                                        <Redirect to='/no-permissions'/>
                                    ) : loggedIn && data && (!userHasNoPermissions) ? (
                                        <Redirect to={
                                            hasPerm(data, "rtcmdmodels.view_request")
                                                ? "/aluminium" :
                                                hasGroup(data, "RT Copper Desk", true)
                                                || hasGroup(data, "Copper Trader", true)
                                                || hasGroup(data, "Copper Back Office", true)
                                                    ? "/coppers"
                                                    : "/orders"}
                                        />
                                    ) : null}
                                    <Login/>
                                </Route>
                                <Route path="/logout">
                                    <Logout/>
                                </Route>
                                {!loggedIn && !loading ? (
                                    <Route path="/">
                                        <Redirect to="/login"/>
                                    </Route>
                                ) : null}
                                {loggedIn && userHasNoPermissions ? (
                                    <Route path="/">
                                        <GlobalToaster/>
                                        <Routes/>
                                    </Route>
                                ) : loggedIn && (!userHasNoPermissions) ? (
                                    <Route path="/">
                                        <GlobalToaster/>
                                        <MarineContext.Provider
                                            value={{
                                                isDarkModeOn,
                                                setIsDarkModeOn,
                                                showImage,
                                                setShowImage,
                                                mainPadding,
                                                setMainPadding
                                            }}>
                                            <Layout>
                                                <Routes/>
                                            </Layout>
                                        </MarineContext.Provider>
                                    </Route>
                                ) : null}
                            </Switch>
                        </AppContext.Provider>
                    </Router>
                </STPMappingSystemColumnContextProvider>
            </MappingValidationContextProvider>
        </SocketContext.Provider>
    );
};

export default App;
