import React, { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Toaster from "../../Toaster";
import { Overlay } from "@blueprintjs/core";
import LoadingIcon from "../../Icons/loading";

import {
  Button,
  DateInput,
  DateRangeInput,
  FormGroup as RTFormGroup,
  Icon,
  TextArea,
} from "rt-design-system-backup";

import Form, { FormContext, FormFooter, InputGroup } from "../../Form";
import AppContext from "../../AppContext";
import { capitalize, getCSRF, isValidNumber, replaceOrderTypes, url, renameInstrument, validatePostponementTrades, getQuoteValidities } from "../../../helpers";

import useLogin from '../../useLogin'

import { types } from "./types";

import "./style.scss";
import { css } from "@emotion/css";

const Column = styled.div`
  max-width: 450px;
`;


export const loadingModelCSS = css({
  '.bp3-overlay-content': {
    borderRadius: '5px',
    backgroundColor: '#BFCAD7',
    left: '45%',
    top: '10%',
    fontSize: '18px',
    padding: 10,
  },
  '.bp3-overlay-content h4': {
    margin: 5,
  }
});


const dateFormat = {
  formatDate: (date) => {
    if (date === "null") return "";

    const y = "" + date.getFullYear();
    const m = "" + (date.getMonth() + 1);
    const d = "" + date.getDate();

    return y + "-" + m.padStart(2, "0") + "-" + d.padStart(2, "0");
  },
  parseDate: (str) => new Date(Date.parse(str)),
};

const TradeInputWrapper = styled.div`
  & > div {
    display: flex;
    flex-direction: row;

    .bp3-form-group {
      margin: 4px;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }

    .rtcmd-button-select {
      flex-wrap: nowrap;
    }
  }
`;

const TradeInput = ({ report, remove, id, maturities, max_volume_uptick, action, allowTradeRemove = true }) => {
  
  return (
    <TradeInputWrapper>
      <Form tag="div" onChange={report}>
        <InputGroup
          defaultValue={action !== null ? [action] : ["Buy"]}
          required
          name="action"
          type="select"
          items={[
            { name: "Buy", value: "Buy" },
            { name: "Sell", value: "Sell" },
          ]}
        />
        <InputGroup
            required
            name="maturity"
            placeholder={"Month"}
            type="select"
            items={maturities}
            resetOnClose={true}
        />
        <InputGroup
          isInvalid={(value) => {
            return isValidNumber(value, true);
          }}
          rightElement={<span style={{ color: '#002C5F' }}>mt</span>}
          required
          customError={max_volume_uptick ? (value) => { return value > max_volume_uptick ? `Qty > ${max_volume_uptick}mt. Contact marketsdesk@riotinto.com for a quote.` : false } : false}
          placeholder="Quantity"
          name="quantity"
          type="number"
        />
        {allowTradeRemove === true ? (
          <div className="bp3-form-group rtcmd-request-form-icon" data-key={id}>
            <Icon onClick={id > 0 ? remove : void 0} icon="remove" />
          </div>
        ): null}
        
      </Form>
    </TradeInputWrapper>
  );
};

const PlantDetailsInput = ({ report, remove, id, items }) => {
  return (
    <TradeInputWrapper>
      <Form tag="div" onChange={report}>
        <InputGroup
            required
            name="plant_details"
            placeholder={"Source Plant"}
            type="select"
            items={items}
            resetOnClose={true}
        />
        <InputGroup
          isInvalid={(value) => {
            return isValidNumber(value, true);
          }}
          rightElement={<span style={{ color: '#002C5F' }}>mt</span>}
          required
          placeholder="Quantity"
          name="value"
          type="number"
        />
        <div className="bp3-form-group rtcmd-request-form-icon" data-key={id}>
          <Icon onClick={id > 0 ? remove : void 0} icon="remove" />
        </div>
      </Form>
    </TradeInputWrapper>
  );
};
const ProductDetailsInput = ({ report, remove, id, items }) => {
  return (
    <TradeInputWrapper>
      <Form tag="div" onChange={report}>
        <InputGroup
            required
            name="product_details"
            placeholder={"Product"}
            type="select"
            items={items}
            resetOnClose={true}
        />
        {// removed value for product but can be added back if needed
        /* <InputGroup
          isInvalid={(value) => {
            return isValidNumber(value, true);
          }}
          rightElement={<span style={{ color: '#002C5F' }}>mt</span>}
          required
          placeholder="Quantity"
          name="value"
          type="number"
        /> */}
        <div className="bp3-form-group rtcmd-request-form-icon" data-key={id}>
          <Icon onClick={id > 0 ? remove : void 0} icon="remove" />
        </div>
      </Form>
    </TradeInputWrapper>
  );
};
const TypeAwareNewRequestForm = ({ type }) => {
  const getTime = () => {
    return new Date().getTime()
  }

  const PostponementAnticipationType = ["Postponement Auto Quote", "Anticipation Auto Quote"]

  const defaultTrade = {
    "id": getTime(),
  }
  const today = new Date();
  const [trades, setTrades] = useState([defaultTrade]);

  const defaultPlantDetails = {
    "id": getTime(),
  }
  const defaultProductDetails = {
    "id": getTime(),
  }
  const [plantDetails, setPlantDetails] = useState([]);
  const [showPlantDetails, setShowPlantDetails] = useState(false);
  const [plantDetailsError, setPlantDetailsError] = useState()
  const [replacementCostError, setReplacementCostError] = useState()
  const [productDetails, setProductDetails] = useState([]);
  const [productDetailsError, setProductDetailsError] = useState()


  const { invalid, setFormContext, ...formContext } = useContext(FormContext);
  // Previously customers/suppliers was needed. change request for PBI 87189
  // const plantDetailsCustomer = ["Strategy - Inventory - unwinding", "Strategy - Inventory hedge", "Strategy - Stock hedge"] 
  const plantSalesEntity = ["RT Alc PM Group"] // Dev testing add in ,"Sales Entity 2"
  const [dateRange, setDateRange] = useState({ start: null, end: null });

  const [isSelectingStart, setIsSelectingStart] = useState(true);
  const maxDate = isSelectingStart ? today : new Date(today.getFullYear() + 2, today.getMonth(), today.getDate());

  const handleDateRangeChange = (newRangeArray) => {
      const newStart = newRangeArray[0];
      const newEnd = newRangeArray[1];

      if (newStart !== dateRange.start) {
          setIsSelectingStart(false);
      } else if (newEnd !== dateRange.end) {
          setIsSelectingStart(true);
      }

      setDateRange({ start: newStart, end: newEnd });
  };

  if (formContext.data.type &&  formContext.data.customer  && showPlantDetails === true && plantSalesEntity.includes(formContext.data.sales_entity[0].name)) {
    if (plantDetails.length === 0) {
      console.log('Plant details should be there')
      defaultPlantDetails.id = getTime()
      defaultProductDetails.id = getTime()
      console.log(defaultPlantDetails)
      console.log(plantDetails)
      setPlantDetails([defaultPlantDetails])
      setProductDetails([defaultProductDetails])
    }
  } else if (plantDetails.length !== 0){
    setPlantDetails([])
    setProductDetails([])
    let copyFormContext = formContext
    copyFormContext.data.plant_details = []
    copyFormContext.data.product_details = []
    setFormContext(copyFormContext)
  }
  /** Acceptable time validities for quotes */
  const [{ loading: quoteValiditiesLoading, data: quoteValidities }] = useAxios(
    {
      url: url("/quote_validity/"),
    }
  );

  /** Acceptable time validities for requests */
  const [
    { loading: requestValiditiesLoading, data: requestValidities },
  ] = useAxios({
    url: url("/request_validity/"),
  });

  /** Benchmarks */
  const [{ loading: benchmarksLoading, data: benchmarks }] = useAxios({
    url: url("/request_benchmark_months/"),
  });

  /** Instruments */
  const [{ loading: instrumentsLoading, data: instruments }] = useAxios({
    url: url("/instrument/"),
  });

  /** Delivery months */
  const [{ loading: deliveryMonthsLoading, data: deliveryMonths }] = useAxios({
    url: url("/request_delivery_months/"),
  });

  const [{ loading: plantDetailsLoading, data: plantDetailsChoices }] = useAxios({
    url: url("/plant_details/"),
  });
  const [{ loading: productDetailsLoading, data: productDetailsChoices }] = useAxios({
    url: url("/product_details/"),
  });
  /** Trade maturities */
  const [{ loading: tradeMaturitiesLoading, data: tradeMaturities }] = useAxios(
    {
      url: url("/maturity/?display_only_request"),
    }
  );

  const [{ data: max_volume_uptick }] = useAxios({
    url: url("/volume_uptick/get_max_volume_uptick/"),
  });

  const filterExistingMaturities = (maturities) => {
    const alreadySelectedMaturities =
      formContext.data &&
        formContext.data.trades &&
        formContext.data.trades.length
        ? formContext.data.trades
          .map((trade) => {
            if (trade && trade.maturity && trade.maturity.length)
              return trade.maturity[0].value;
            return false;
          })
          .filter((item) => item)
        : [];
    return maturities.filter((maturity) => {
      return alreadySelectedMaturities.indexOf(maturity.value) < 0;
    });
  };

  const filterExistingPlantDetails = (tempPlantDetails) => {
    const alreadySelectedPlantDetails =
      formContext.data &&
        formContext.data.plant_details &&
        formContext.data.plant_details.length
        ? formContext.data.plant_details
          .map((plantDetail) => {
            if (plantDetail && plantDetail.plant_details && plantDetail.plant_details.length)
              return plantDetail.plant_details[0].value;
            return false;
          })
          .filter((item) => item)
        : [];
    return tempPlantDetails.filter((plantDetail) => {
      return alreadySelectedPlantDetails.indexOf(plantDetail[0]) < 0;
    });
  };

  const updateFormContext = (key, valid) => {
    setFormContext((formContext) => {
      if (!valid && formContext.invalid.indexOf(key) < 0) {
        return {
          invalid: [...formContext.invalid, key],
        };
      } else if (valid && formContext.invalid.indexOf(key >= 0)) {
        return {
          invalid: [
            ...[...formContext.invalid].filter((eName) => eName !== key),
          ],
        };
      }
      return {};
    });
  }

  const updateTradesStatus = () => {
    let valid = true;
    let data = [];

    trades.forEach((trade) => {
      if (trade.valid !== true) {
        valid = false;
      }

      data.push(trade.data);
    });

    updateFormContext("trades", valid)

    if (valid === false || trades.length === 0  || !PostponementAnticipationType.includes(type)) return;
    const isValidReplacement = validatePostponementTrades(trades, type)
    if (isValidReplacement.isValid === true) {
      setFormContext((formContext) => {
        formContext.invalid_postponement_trades = false
        return formContext
      });
      setReplacementCostError()
    } else {
      setFormContext((formContext) => {
        formContext.invalid_postponement_trades = true
        return formContext
      });
      setReplacementCostError(isValidReplacement.error)
    }
  };

  useEffect(() => {
    if (PostponementAnticipationType.includes(type)) setTrades([defaultTrade, {"id": getTime()}])
    // eslint-disable-next-line
  }, [type])

  useEffect(() => {
    let totalOffers = 0
    let totalBuy = 0
    let totalSell = 0 
    let totalPlantDetails = 0
    trades.forEach((trade) => {
      if (trade.data && trade.data.action && trade.data.quantity) {
        if (trade.data.action[0].name === 'Sell')
          totalSell += parseInt(trade.data.quantity)
        else
          totalBuy += parseInt(trade.data.quantity)
        }
    })
    totalOffers = totalBuy === 0 ? totalSell:totalBuy
    plantDetails.forEach((plantDetail) => {
      if (plantDetail.data && plantDetail.data.value){ 
        totalPlantDetails += parseInt(plantDetail.data.value)}
    })

    if (plantDetails.length !== 0 &&  totalOffers !== totalPlantDetails) {
      setFormContext((formContext) => {
        formContext.invalid_plant_details = true
        return formContext
      });
      setPlantDetailsError('Sum of all Source Plants must be equal to sum of bids')
    } else {
      setFormContext((formContext) => {
        formContext.invalid_plant_details = false
        return formContext
      });
      setPlantDetailsError()
    }
    

    // eslint-disable-next-line
  }, [plantDetails, trades])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    updateTradesStatus();
    setFormContext({
      data: {
        trades: trades.map((trade) => trade.data),
      },
    });
    let totalBids, totalOffers = 0
    trades.forEach((trade) => {
      if (trade.data && trade.data.action && trade.data.action[0].name === 'Buy' && trade.data.quantity) {
        if (totalBids === undefined) {
          totalBids = parseInt(trade.data.quantity)
        } else totalBids += parseInt(trade.data.quantity)
      }
      if (trade.data && trade.data.action && trade.data.action[0].name === 'Sell' && trade.data.quantity) totalOffers += parseInt(trade.data.quantity)
    })
    if (totalBids !== undefined ||  totalOffers !== undefined) setShowPlantDetails(true)
    else setShowPlantDetails(false)


  }, [trades]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const report = (key) => {
    return (data) => {
      const tradesCopy = [...trades];
      const tradeId = tradesCopy[key].id
      tradesCopy[key] = data;
      tradesCopy[key]['id'] = tradeId;

      if (JSON.stringify(data) !== JSON.stringify(trades[key])) {
        setTrades(tradesCopy);
      }
    };
  };

  const remove = (key) => {
    return () => {
      const tradesCopy = [...trades].filter((_, itemKey) => itemKey !== key);
      setTrades(tradesCopy);
    };
  };

  const updatePlantDetailsStatus = () => {
    let valid = true;
    let data = [];

    plantDetails.forEach((plantDetail) => {
      if (plantDetail.valid !== true) {
        valid = false;
      }

      data.push(plantDetail.data);
    });

    updateFormContext("plant_details", valid);
  };

  useEffect(() => {
    if (plantDetails.length) {
      updatePlantDetailsStatus();
      setFormContext({
        data: {
          plant_details: plantDetails.map((plantDetail) => plantDetail.data),
        },
      });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantDetails]);

  const updateProductDetailsStatus = () => {
    let valid = true;
    let data = [];

    productDetails.forEach((productDetail) => {
      if (productDetail.valid !== true) {
        valid = false;
      }

      data.push(productDetail.data);
    });

    updateFormContext("product_details", valid);
  };
  useEffect(() => {
    if (productDetails.length) {
      updateProductDetailsStatus();
      setFormContext({
        data: {
          product_details: productDetails.map((productDetail) => productDetail.data),
        },
      });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);
  const reportPlantDetails = (key) => {
    return (data) => {
      const plantDetailsCopy = [...plantDetails];
      const plantDetailId = plantDetailsCopy[key].id
      plantDetailsCopy[key] = data;
      plantDetailsCopy[key]['id'] = plantDetailId;

      if (JSON.stringify(data) !== JSON.stringify(plantDetails[key])) {
        setPlantDetails(plantDetailsCopy);
      }
    };
  };

  const removePlantDetails = (key) => {
    return () => {
      const plantDetailsCopy = [...plantDetails].filter((_, itemKey) => itemKey !== key);
      setPlantDetails(plantDetailsCopy);
    };
  };

    const reportProductDetails = (key) => {
    return (data) => {
      const productDetailsCopy = [...productDetails];
      const productDetailId = productDetailsCopy[key].id
      productDetailsCopy[key] = data;
      productDetailsCopy[key]['id'] = productDetailId;

      if (JSON.stringify(data) !== JSON.stringify(productDetails[key])) {
        setProductDetails(productDetailsCopy);
      }
    };
  };

  const removeProductDetails = (key) => {
    return () => {
      const productDetailsCopy = [...productDetails].filter((_, itemKey) => itemKey !== key);
      setProductDetails(productDetailsCopy);
    };
  };


  const loading =
    quoteValiditiesLoading ||
    requestValiditiesLoading ||
    benchmarksLoading ||
    instrumentsLoading ||
    deliveryMonthsLoading ||
    plantDetailsLoading ||
    productDetailsLoading ||
    tradeMaturitiesLoading;

  return loading ? (
    "Loading..."
  ) : (
    <>
      <InputGroup
        required
        type="select"
        title="Instrument"
        name="instrument"
        key="instrument"
        items={instruments
          .map((item) => {
            if (type === "At Market") {
              if (item[0][1])
                return {
                  name: renameInstrument(item[0][1]),
                  value: item[0][1],
                };
              return false;
            }
            return {
              name: renameInstrument(item[1]),
              value: item[0].join(":::"),
            };
          })
          .filter((item) => item)}
      />
      {["Quote", "Auto Quote", "Indic Quote", "Postponement Auto Quote", "Anticipation Auto Quote"].includes(type) ? (
        <>
          <p style={{ color: '#000000', fontSize: '16px', marginBottom: 2 }}>Quote validity</p>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <InputGroup
              required
              type="select"
              name="validity"
              key={`validity_${type}`}
              items={getQuoteValidities(quoteValidities, type).map((item) => {
                return {
                  name: item[0],
                  value: item[0],
                };
              })}
              defaultValue={PostponementAnticipationType.includes(type) ? [getQuoteValidities(quoteValidities, type)[0][0]] : null}
            />
            <div style={{ margin: '10px' }}></div>
            <FormContext.Consumer>
              {(ctx) =>
                ctx.data.validity &&
                  ctx.data.validity.length &&
                  ctx.data.validity[0].value === "Specific date" ? (
                  <DateInput
                    inputProps={{ name: "valid_until" }}
                    {...dateFormat}
                    minDate={new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate())}
                    maxDate={new Date(today.getFullYear()+2, today.getMonth(), today.getDate())}
                    required
                    key="valid_until"
                  />
                ) : null
              }
            </FormContext.Consumer>
          </div>
        </>
      ) : null}
      {["CSP", "APC", "Limit Order"].indexOf(type) >= 0 ? (
        <InputGroup
          required
          type="select"
          title="Pricing basis"
          name="benchmark"
          key="benchmark"
          onChange={(val) => {
            if (val.length && val[0].value === "Maturity") {
              setTrades(trades.slice(0, 1));
            }
          }}
          items={benchmarks
            .map((item) => {
              return {
                name: item[0],
                value: item[0],
              };
            })
            .filter((item) => {
              if (type === "CSP" || type === "APC") {
                return item.value !== "Maturity";
              }
              return true;
            })}
        />
      ) : null}
      {type === "Limit Order" ? (
        <>
          <InputGroup
            required
            type="number"
            title="Target price"
            key="target_price"
            name="requested_price"
            isInvalid={(value) => {
              return isValidNumber(value, true);
            }}
          />
        </>
      ) : null}
      {type === "Limit Order" ? (
        <>
          <p style={{ color: '#706F6F' }}>Valid until</p>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <InputGroup
              required
              type="select"
              name="validity"
              key="validity"
              items={requestValidities.map((item) => {
                return {
                  name: item[0],
                  value: item[0],
                };
              })}
            />
            <div style={{ margin: '10px' }}></div>
            <FormContext.Consumer>
              {(ctx) =>
                ctx.data.validity &&
                  ctx.data.validity.length &&
                  ctx.data.validity[0].value === "Good until specific date" ? (
                  <DateInput
                    inputProps={{ name: "valid_until" }}
                    {...dateFormat}
                    minDate={today}
                    maxDate={new Date(today.getFullYear()+2, today.getMonth(), today.getDate())}
                    required
                    key="valid_until"
                  />
                ) : null
              }
            </FormContext.Consumer>
          </div>
        </>
      ) : null}
      {["APC"].indexOf(type) >= 0 ? (
        <RTFormGroup label="Pricing period">
                <DateRangeInput
                    singleMonthOnly
                    startInputProps={{ name: "pricing_period_start_date" }}
                    endInputProps={{ name: "pricing_period_end_date" }}
                    {...dateFormat}
                    onChange={handleDateRangeChange}
                    value={[dateRange.start, dateRange.end]}
                    maxDate={new Date(today.getFullYear()+5, today.getMonth(), today.getDate())}
                    required
                    key="pricing_period"
                />
        </RTFormGroup>
      ) : null}
      {["CSP"].indexOf(type) >= 0 ? (
        <RTFormGroup label="Pricing period">
          <DateInput
            inputProps={{ name: "pricing_period_end_date" }}
            {...dateFormat}
            minDate={today}
            maxDate={new Date(today.getFullYear()+2, today.getMonth(), today.getDate())}
            required
            key="pricing_period_end"
          />
        </RTFormGroup>
      ) : null}
      {PostponementAnticipationType.includes(type) ? null : (
        <InputGroup
          required
          type="button-select"
          title="Delivery"
          name="delivery_months"
          defaultValue={type === "At Market" ? ["Future"] : ["Spot"]}
          key="delivery_months"
          onChange={(target) => {
            if (target === "Spot") {
              setTrades([defaultTrade]);
            } else if (trades.length > 1) {
              setTrades([defaultTrade]);
            }
          }}
          items={deliveryMonths.map((item) => {
            return {
              name: item[1],
              value: item[0],
            };
          })}
        />
      )}
      <p style={{ color: '#000000', fontSize: '16px', marginBottom: 0 }}>Maturities</p>
      <FormContext.Consumer>
        {(ctx) => {
          const isSpot =
            ctx.data &&
            ctx.data.delivery_months &&
            ctx.data.delivery_months.length &&
            ctx.data.delivery_months[0] === "Spot";

          const isMaturityBenchmark =
            ctx.data &&
            ctx.data.benchmark &&
            ctx.data.benchmark.length &&
            ctx.data.benchmark[0].value === "Maturity";

          return (
            <>
              {trades.map((trade, key) => {
                return (
                  <TradeInput
                    key={(isSpot ? "spot." : "future.") + trade.id}
                    id={key}
                    remove={remove(key)}
                    report={report(key)}
                    max_volume_uptick={["Postponement Auto Quote", "Anticipation Auto Quote", "Auto Quote", "Indic Quote"].includes(type)? max_volume_uptick : false}
                    maturities={filterExistingMaturities(
                      (PostponementAnticipationType.includes(type)
                        ? tradeMaturities
                        : isSpot
                        ? tradeMaturities.slice(0, 1)
                        : tradeMaturities.slice(1)
                      ).map((maturity) => {
                        return {
                          value: maturity.id,
                          name: capitalize(maturity.shortened_string),
                        };
                      })
                    )}
                    allowTradeRemove={!PostponementAnticipationType.includes(type)}
                    action={type === "Postponement Auto Quote" ? key === 0 ? "Sell": "Buy" : type === "Anticipation Auto Quote" ? key === 0 ? "Buy" : "Sell" : null}
                  />
                );
              })}
              {replacementCostError ? <span style={{color: 'red'}}>{replacementCostError}</span> : null}
              {isSpot || PostponementAnticipationType.includes(type) || isMaturityBenchmark || (ctx.data.type && ctx.data.type[0]["name"] === 'Limit order') ? null : (
                <Button
                  onClick={() => {
                    defaultTrade.id = getTime()
                    setTrades([...trades, defaultTrade]);
                  }}
                  icon="add"
                  minimal
                >
                  Add maturity
                </Button>
              )}
              {ctx.data.type && ctx.data.customer && showPlantDetails === true && plantSalesEntity.includes(formContext.data.sales_entity[0].name) && (
                <> 
                  <p style={{ color: '#000000', fontSize: '16px', marginBottom: 0 }}>Source Plant</p>
                  {plantDetails.map((plantDetail, key) => {
                    return (
                      <PlantDetailsInput
                        key={plantDetail.id}
                        id={key}
                        remove={removePlantDetails(key)}
                        report={reportPlantDetails(key)}
                        items={filterExistingPlantDetails(plantDetailsChoices.sort((a, b) => {
                          if (a[0] === "Other") return 1;
                          if (a[0] < b[0]) return -1;
                          if (a[0] > b[0]) return 1;
                          return 0;
                        })).map((pDetail) => {
                          return {
                            value: pDetail[0],
                            name: pDetail[0],
                          };})}
                      />
                    );
                  })}
                  {plantDetailsError ? <span style={{color: 'red'}}>{plantDetailsError}</span> : null}
                  <Button
                    onClick={() => {
                      defaultPlantDetails.id = getTime()
                      setPlantDetails([...plantDetails, defaultPlantDetails]);
                    }}
                    icon="add"
                    minimal
                  >
                    Add Source Plants
                  </Button>
                </>
                
              )}
              {ctx.data.type && ctx.data.customer && showPlantDetails === true && plantSalesEntity.includes(formContext.data.sales_entity[0].name) && ( // using exact same condition as plants
                <> 
                  <p style={{ color: '#000000', fontSize: '16px', marginBottom: 0 }}>Product</p>
                  {productDetails.map((productDetail, key) => {
                    return (
                      <ProductDetailsInput
                        key={"prod"+productDetail.id}
                        id={key}
                        remove={removeProductDetails(key)}
                        report={reportProductDetails(key)}
                        items={filterExistingPlantDetails(productDetailsChoices.sort((a, b) => {
                          if (a[0] === "Other") return 1;
                          if (a[0] < b[0]) return -1;
                          if (a[0] > b[0]) return 1;
                          return 0;
                        })).map((pDetail) => {
                          return {
                            value: pDetail[0],
                            name: pDetail[0],
                          };})}
                      />
                    );
                  })}
                  {productDetailsError ? <span style={{color: 'red'}}>{productDetailsError}</span> : null}
                  {// removed button for now. but can be added back when needed.
                  /* <Button
                    onClick={() => {
                      defaultProductDetails.id = getTime()
                      setProductDetails([...productDetails, defaultProductDetails]);
                    }}
                    icon="add"
                    minimal
                  >
                    Add Products
                  </Button> */}
                </>
                
              )}
            </>
          );
        }}
      </FormContext.Consumer>
    </>
  );
};

const NewRequestForm = () => {
  const { user } = useContext(AppContext);
  const [showLoadingModel, setShowLoadingModel] = useState(false);

  useLogin(true)

  /** Common fields */
  const [{ loading: salesEntitiesLoading, data: salesEntities }] = useAxios({
    url: url("/sales_entity/"),
  });

  const [{ loading: customersLoading, data: customers }] = useAxios({
    url: url("/customer/"),
  });

  const [{ loading: internalPortfolioLoading, data: internalPortfolio }] = useAxios({
    url: url("/internal_portfolio/"),
  });


  const [{ loading: currenciesLoading, data: currencies }] = useAxios({
    url: url("/currency/"),
  });

  const commonLoading =
    customersLoading || salesEntitiesLoading || currenciesLoading;

  const loading = commonLoading || !user || internalPortfolioLoading;

  const history = useHistory();

  const [{ loading: createLoading }, createRequest] = useAxios(
    {
      url: "",
      method: "POST",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const send = async (formValues, formState) => {
    // Unsure why the complication trying to use values from both form values and state
    // state should contain all necessary values
    const endpoint = url(formState.data.type[0].url);

    const data = {
      ...formValues,
    };

    data.request_type = data.type;
    console.log(JSON.parse(JSON.stringify(data))) // Deep copy to check data 
    console.log(JSON.parse(JSON.stringify(formState))) // Deep copy to check data 
    console.log(JSON.parse(JSON.stringify(formValues))) // Deep copy to check data 
    delete data?.action;
    delete data?.quantity;
    delete data?.type;
    delete data?.maturity;

    if (formValues.instrument) {
      data.instruments = formValues.instrument.split(":::");
    }

    delete data?.instrument;

    data.trades = (formState.data.trades || []).map((trade) => {
      return {
        action: trade.action[0].value,
        maturity: trade.maturity[0].value,
        quantity: trade.quantity,
      };
    });
    data.plant_details = (formState.data.plant_details || []).map((plant_detail) => {
      return {
        plant_details: plant_detail.plant_details[0].value,
        value: plant_detail.value
      }
    })
    data.product_details = (formState.data.product_details || []).map((product_detail) => {
      return {
        product_details: product_detail.product_details[0].value,
        // value: product_detail.value
      }
    })
    console.log("end of formatting data for new form")
    
    console.log(JSON.parse(JSON.stringify(data))) // Deep copy to check data 
    try {
      const createdRequest = await createRequest({
        url: endpoint,
        data,
      });
      if (["Postponement Auto Quote", "Anticipation Auto Quote", "Auto Quote", "Indic Quote"].includes(createdRequest.data.type)) {
        history.push(`/aluminium/request/${createdRequest.data.id}/details`);
      } else {
        history.goBack();
      }
      Toaster.show({
        message: "Request successfully placed. ",
        icon: "small-tick",
        intent: "success",
      });
    } catch (err) {
      setShowLoadingModel(false)
      Toaster.show({
        message: (err.response.data && err.response.data.error) || "An error occurred while creating the request. ",
        icon: "warning-sign",
        intent: "danger",
      });
    }
  };

  return loading ? (
    "Loading..."
  ) : (
    <Form onSubmit={send}>
      <Overlay
        isOpen={showLoadingModel}
        className={loadingModelCSS}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <LoadingIcon /><h4>Generating request</h4>
        </div>
      </Overlay>
      <h1>Create new request</h1>
      <hr />
      <Column>
        <h2>Request details</h2>
        <InputGroup
          required
          name="type"
          type="select"
          title="Type"
          defaultValue={[types[1].value]}
          items={types.filter((requestType) => user.is_admin ? true : !["Postponement Auto Quote", "Anticipation Auto Quote"].includes(requestType.name)).map((item) => {
            return {
              name: replaceOrderTypes(item.name),
              value: item.value,
              url: item.url,
            };
          }).sort((a, b) => a.name.localeCompare(b.name))}
        />
        <InputGroup
          required
          type="select"
          title="Sales entity"
          name="sales_entity"
          items={salesEntities.map((item) => {
            return {
              name: item.name,
              value: item.id,
              currency: item.currency
            };
          })}
          defaultValue={user.sales_entity ? [user.sales_entity.id] : undefined}
        />
        
        <InputGroup
          required
          name="customer"
          type="select"
          title="Customer/Supplier"
          items={customers.map((item) => {
            return {
              name: item.display_name,
              value: item.id,
            };
          }).sort((a, b) => a.name.localeCompare(b.name))}
        />
        
        <FormContext.Consumer>
          {(ctx) => {
            return (
              <>
              <InputGroup
                required
                type="select"
                name="currency"
                title="Currency"
                key={
                  "currency." +
                  (ctx.data.type && ctx.data.type.length
                    ? ctx.data.type[0].value
                    : "") + "." +
                  (ctx.data.sales_entity && ctx.data.sales_entity.length
                    ? ctx.data.sales_entity[0].value
                    : "")
                }
                defaultValue={(() => {
                  if (
                    ctx.data.type &&
                    ctx.data.type.length &&
                    ["APC", "Postponement Auto Quote", "Anticipation Auto Quote"].includes(ctx.data.type[0].value)
                  )
                    return ["USD"];

                  if (ctx.data.sales_entity && ctx.data.sales_entity.length && ctx.data.sales_entity[0].currency) return [ctx.data.sales_entity[0].currency]

                  return undefined
                })()}
                items={currencies
                  .map((item) => {
                    return {
                      name: `${item[1]}`.toUpperCase(),
                      value: item[0],
                    };
                  })
                  .filter((item) => {
                    if (
                      ctx.data.type &&
                      ctx.data.type.length &&
                      ["APC", "Postponement Auto Quote", "Anticipation Auto Quote"].includes(ctx.data.type[0].value)
                    )
                      return item.value === "USD";
                    return true;
                  })}
              />
              {ctx.data.sales_entity && ctx.data.sales_entity.length && ctx.data.sales_entity[0].name === 'RT Mktg Pte Ltd PM' &&
                <InputGroup
                  required
                  name="internal_portfolio"
                  type="select"
                  title="Internal Portfolio"
                  items={internalPortfolio.map((item) => {
                    return {
                      name: item[0],
                      value: item[0],
                    };
                  })}
                />
              }
            </>);
          }}
        </FormContext.Consumer>
      </Column>
      <hr />
      <Column>
        <h2>Pricing instructions</h2>
        <FormContext.Consumer>
          {(ctx) => {
            const data = ctx.data;
            return data.type && data.type.length ? (
              <>
                <TypeAwareNewRequestForm type={data.type[0].value} />
              </>
            ) : (
              <p>
                Select an order type before proceeding with pricing
                instructions.
              </p>
            );
          }}
        </FormContext.Consumer>
      </Column>
      <hr />
      <Column>
        <h2>Additional</h2>
        <RTFormGroup label="Comments">
          <TextArea style={{ width: '655px' }} growVertically={true} name="comments"></TextArea>
        </RTFormGroup>
      </Column>
      <FormFooter>
        <Button
          onClick={() => {
            history.push("/aluminium");
          }}
          text="Cancel"
          minimal
          large
        />
        <FormContext.Consumer>
          {({ valid, data }) => {
            return (
              <Button
                large
                loading={createLoading}
                disabled={!valid}
                type="submit"
                intent="primary"
                onClick={data.type && data.type.length && (["Postponement Auto Quote", "Anticipation Auto Quote", "Auto Quote", "Indic Quote"].includes(data.type[0].value)) ? () => setShowLoadingModel(true) : () => { }}
                text={data.type && data.type.length && (["Postponement Auto Quote", "Anticipation Auto Quote", "Auto Quote", "Indic Quote"].includes(data.type[0].value)) ? "Generate request" : "Create request"}
              />);
          }}
        </FormContext.Consumer>
      </FormFooter>
      <div style={{ display: "block", height: "32px" }}></div>
    </Form>
  );
};

const NewRequest = () => {
  return <NewRequestForm />;
};

export default NewRequest;
