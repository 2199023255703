import React, {useContext} from "react";

import {Route, Switch, Redirect} from "react-router-dom";

import NewRequest from "../Views/NewRequest";
import FillRequest from "../Views/FillRequest";
import Requests from "../Views/Requests";
import RequestDetail from "../Views/RequestDetail";
import UpdateRequest from "../Views/UpdateRequest";
import Maturities from "../Views/Maturities";
import Dashboard from "../Views/Dashboard";
import Configuration from "../Views/Configuration";
import {hasGroup} from "../../helpers";
import AppContext from "../AppContext";
import AllocateRequest from "../Views/AllocateRequest";
import AnalyticsDashboard from "../Views/AnalyticsDashboard";

const AluminiumRouting = () => {
  const {user} = useContext(AppContext);

  return <Switch>
          <Route path="/aluminium/request/">
            <Switch>
              <Route path="/aluminium/request/new">
                <NewRequest/>
              </Route>
              <Route path="/aluminium/request/:requestId/update">
                <UpdateRequest/>
              </Route>
              <Route path="/aluminium/request/:requestId/fill-edit">
                <FillRequest editRequest={true}/>
              </Route>
              <Route path="/aluminium/request/:requestId/fill">
                <FillRequest/>
              </Route>
              <Route path="/aluminium/request/:requestId/allocate">
                <AllocateRequest />
              </Route>
              <Route path="/aluminium/request/:requestId/details" exact>
                <RequestDetail/>
              </Route>
              <Route path="/aluminium/request/:requestId/reconcile" exact>
                <RequestDetail action="reconcile"/>
              </Route>
              <Route path="/aluminium/request/:requestId/" exact>
                <Redirect to="/aluminium"/>
              </Route>
            </Switch>
            <Route path="/aluminium/request/:requestId/"></Route>
          </Route>
          <Route path="/aluminium/trades">
            <Maturities/>
          </Route>
          <Route path="/aluminium/dashboard">
            <Dashboard/>
          </Route>
          <Route path="/aluminium/analytics-dashboard/main" render={() => 
            user.is_admin ? <AnalyticsDashboard tab={'main'}/> : <Redirect to="/aluminium"/>} 
          />  
          <Route path="/aluminium/analytics-dashboard/customers" render={() => 
            user.is_admin ? <AnalyticsDashboard tab={'customers'}/> : <Redirect to="/aluminium"/>} 
          />  
          <Route path="/aluminium/configuration/LMEPrice" render={() =>
            hasGroup(user, 'Alu Trader') ? <Configuration configType={'LMEPrice'}/> : <Redirect to="/aluminium"/>}
          />
          <Route path="/aluminium/configuration/MarketPremium" render={() =>
            hasGroup(user, 'Alu Trader') ? <Configuration configType={'MarketPremium'}/> : <Redirect to="/aluminium"/>}
          />
          <Route path="/aluminium/configuration/TierCustomer" render={() =>
            user.is_admin ? <Configuration configType={'TierCustomer'}/> : <Redirect to="/aluminium"/>}
          />
          <Route path="/aluminium" exact>
            <Requests/>
          </Route>
          <Redirect to="/aluminium"/>
        </Switch>
};

export default AluminiumRouting;
