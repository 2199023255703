import React, { useContext, useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";

import Logo from "../../../assets/NexTrade_Colour_logo_Vector_DARK_BG.png";
import LogoTextSVG from "../../../assets/NexTrade_WORD_logo_Vector_WHITE.svg";

import mobileLogo from "./RT-R-logo.svg";
import {
  Button,
  Icon,
  Navbar,
  NavbarGroup,
  NavbarHeading,
  Popover,
} from "rt-design-system-backup";
import { Position } from "@blueprintjs/core";
import DarkModeLogo from "./moon-sharp.svg";
import LightModeLogo from "./moon-outline.svg";

import AppContext from "../AppContext";

import NavLink from "./link";
import "./style.scss";
import { hasGroup, hasPerm } from "../../helpers";
import { Dropdown, Menu as MenuAnt } from "antd";
import MarineContext from "../MarineContext";

const DropdownComponent = ({ links, label, subLink }) => {
  const history = useHistory();
  const [dropDownVisible, setDropDownVisible] = useState(false);

  const handleMenuClick = (e) => {
    history.push(e.key);
  };

  const menu = (
    <MenuAnt onClick={handleMenuClick} style={{ marginTop: 25 }}>
      {links.map((link) => (
        <MenuAnt.Item
          onClick={() => setDropDownVisible(false)}
          key={link["link"]}
          style={{ fontSize: "16px", padding: 12, height: "48px" }}
        >
          {link["label"]}
        </MenuAnt.Item>
      ))}
    </MenuAnt>
  );

  return (
    <Dropdown
      onVisibleChange={(v) => {
        setDropDownVisible(v);
      }}
      overlay={menu}
    >
      <h2 style={{ display: "inline" }}>
        <NavLink subLink={subLink}>
          {label}{" "}
          <Icon icon={dropDownVisible ? "chevron-up" : "chevron-down"} />
        </NavLink>
      </h2>
    </Dropdown>
  );
};

const Nav = () => {
  const { user } = useContext(AppContext);
  const [logoutDropDown, setLogoutDropDown] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  let navLinks = [{ link: "/aluminium", label: "All Requests" }];
  const orderNavLinks = [
    { link: "/orders", label: "All Orders" },
    { link: "/orders/trades", label: "All Trades" },
  ];

  const copperNavLinks = [
    { link: "/coppers/", label: "All Orders" },
    { link: "/coppers/trades/", label: "All Trades" },
  ];

  const bunkerLinks = [{ link: "/bunker", label: "Bunker" }];

  const ffaNavLinks = [
    { link: "/ffa/dashboard", label: "Dashboard" },
    { link: "/ffa/metric-dashboard", label: "Metric Dashboard" },
    { link: "/ffa/historical_dashboard", label: "Historical Data" },
  ];
  const stpMappingNavLinks = [
    { link: "/stp-mapping-console", label: "STP Mapping Console" },
    { link: "/stp-api-console", label: "STP API Console" },
    { link: "/stp-logs", label: "Logs" },
  ];

  if (hasGroup(user, "Alu Trader")) {
    navLinks.push({
      link: "/aluminium/configuration/MarketPremium",
      label: "Configurations",
    });
  }

  if (user.is_admin) {
    navLinks.push({
      link: "/aluminium/analytics-dashboard/main",
      label: "Analytics Dashboard",
    });
  }

  if (hasPerm(user, "rtcmdmodels.change_request")) {
    navLinks.push({ link: "/aluminium/dashboard", label: "Dashboard" });
  }

  useEffect(() => {
    window.addEventListener("resize", () =>
      setIsMobile(window.innerWidth <= 900)
    );
  }, []);

  return (
    <nav>
      <Navbar
        align="left"
        className="dark_version"
        style={{ minWidth: "328px", minHeight: "76px" }}
      >
        <NavbarGroup>
        <a href="/">
          <NavbarHeading>
            <img src={Logo}
            style={{
              width: "70px", 
              marginTop: isMobile ? "0px" : "-10px",
              marginLeft: isMobile ? "0px" : "20px",
            }}></img>
            <SVG src={LogoTextSVG}
            style={{
              width: "90px",
              marginTop: isMobile ? "30px" : "0px",
              marginRight: isMobile ? "0px" : "20px",
              }}/>
          </NavbarHeading>
          </a>
          <ul
            style={{
              whiteSpace: "nowrap",
              maxWidth: isMobile ? "420px" : "100%",
              overflowX: "auto",
              overflowY: "visible",
              paddingBottom: "50px",
              marginBottom: isMobile ? "-52px" : "-50px",
            }}
            className={"nav_links"}
          >
            {hasPerm(user, "rtcmdmodels.view_request") ? (
              <DropdownComponent
                links={navLinks}
                label={"Aluminium"}
                subLink={"/aluminium"}
              />
            ) : null}
            {hasPerm(user, "rtcmdmodels.view_copper") ? (
              <DropdownComponent
                links={copperNavLinks}
                label={"Copper"}
                subLink={"/copper"}
              />
            ) : null}
            {hasPerm(user, "rtcmdmodels.view_order") ? (
              <DropdownComponent
                links={orderNavLinks}
                label={"Marine"}
                subLink={"/order"}
              />
            ) : null}
            {hasPerm(user, "rtcmdmodels.view_order") ? (
              <DropdownComponent
                links={ffaNavLinks}
                label={"FFA"}
                subLink={"/ffa"}
              />
            ) : null}
            {hasPerm(user, "rtcmdmodels.view_order") ? (
              <DropdownComponent
                links={bunkerLinks}
                label={"Bunker"}
                subLink={"/bunker"}
              />
            ) : null}
            {hasPerm(user, "rtcmdmodels.view_order") ? (
              <DropdownComponent
                links={stpMappingNavLinks}
                label={"Admin"}
                subLink={"/stp-mapping"}
              />
            ) : null}
          </ul>
        </NavbarGroup>
        <NavbarGroup align="right" className="nav-right logout-btn">
          <MarineContext.Consumer>
            {({ isDarkModeOn, setIsDarkModeOn, showImage }) =>
              showImage === true && (
                <SVG
                  src={isDarkModeOn ? DarkModeLogo : LightModeLogo}
                  onClick={() => {
                    setIsDarkModeOn(!isDarkModeOn);
                  }}
                  style={{ width: "25px", marginRight: 20 }}
                />
              )
            }
          </MarineContext.Consumer>
          <span className="rtcmd-email">
            {user ? user.email : "Not logged in"}
          </span>
          <Popover
            content={
              <Link style={{ textDecoration: "none" }} to={"/logout"}>
                <Button
                  style={{ backgroundColor: "#f6f6f6" }}
                  icon={"log-out"}
                  minimal
                  text={"Log out"}
                  large
                />
              </Link>
            }
            onClosing={() => setLogoutDropDown(false)}
            onOpening={() => setLogoutDropDown(true)}
            position={Position.TOP}
          >
            <Button
              minimal
              icon={logoutDropDown ? "caret-up" : "caret-down"}
              intent="primary"
            />
          </Popover>
        </NavbarGroup>
      </Navbar>
    </nav>
  );
};

export default Nav;
